import { Box, Collapse } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AccountsTableFooter } from '../AccountsTableFooter';
import { Actions } from '@models/enums/Actions';
import { AdjustmentsTable } from '../AdjustmentsTable';
import ChevronDownIcon from '@assets/icons/chevron-down.svg';
import ChevronUpIcon from '@assets/icons/chevron-up.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import { getAdjustments } from '@services/api';
import useStyles from './styles';

enum AdjustmentOperation {
  Initial = 'initial',
  Manual = 'manual',
  Add = 'add',
  Subtract = 'subtract',
  Match = 'match',
  Fees = 'fees',
  Balance = 'balance',
}

interface IAccountBalance {
  accountId: string;
  accountName: string;
  sourceBalance: number;
  manualAdjustment: number;
  glAdjustment: number;
  fees: number;
  totalBalance: number;
}

interface IProps {
  project: IProject;
  accounts: IAccount[];
  type: number;
  category: string;
}

export const AccountsTable = ({ project, accounts, type, category }: IProps) => {
  const { classes } = useStyles();

  const [emptyAccountsVisibility, setEmptyAccountsVisibility] = useState(false);
  const [unadjustedAccountsVisibility, setUnadjustedAccountsVisibility] = useState(false);

  const [initialAdjustments, setInitialAdjustments] = useState<IAdjustment[]>([]);
  const [manualAdjustments, setManualAdjustments] = useState<IAdjustment[]>([]);
  const [glAddAdjustments, setGlAddAdjustments] = useState<IAdjustment[]>([]);
  const [glSubtractAdjustments, setGlSubtractAdjustments] = useState<IAdjustment[]>([]);
  const [glMatchAdjustments, setGlMatchAdjustments] = useState<IAdjustment[]>([]);
  const [glBalanceAdjustments, setGlBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [feesAdjustments, setFeesAdjustments] = useState<IAdjustment[]>([]);
  const [height, setHeight] = useState(250);
  const [showDetails, setShowDetails] = useState(false);

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setInitialAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Initial),
          );
          setManualAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Manual),
          );
          setGlAddAdjustments(data.items.filter((x) => x.operation === AdjustmentOperation.Add));
          setGlSubtractAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Subtract),
          );
          setGlMatchAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Match),
          );
          setGlBalanceAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Balance),
          );
          setFeesAdjustments(data.items.filter((x) => x.operation === AdjustmentOperation.Fees));
        }
      },
    },
  );

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const onDecreaseTableHeight = () => {
    setHeight((prev) => Math.max(prev/2, 250));
  };

  const onIncreaseTableHeight = () => {
    setHeight((prev) => Math.min(prev*2, 1000));
  };

  const onChangeEmptyAccountsVisibility = (value: boolean) => {
    setEmptyAccountsVisibility(value);
  };

  const onChangeUnadjustedAccountsVisibility = (value: boolean) => {
    setUnadjustedAccountsVisibility(value);
  };

  const onToggleShowDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const columns = useMemo(
    () =>
      [
        {
          field: 'accountName',
          headerName: 'Account',
          type: 'string',
          flex: 1,
        },
        {
          field: 'sourceBalance',
          headerName: 'Source Balance',
          type: 'number',
          flex: 1,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
        {
          field: 'manualAdjustment',
          headerName: 'Manual Adjustment',
          type: 'number',
          flex: 1,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
        {
          field: 'glAdjustment',
          headerName: 'GL Adjustments',
          type: 'number',
          flex: 1,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
        {
          field: 'fees',
          headerName: 'Fees',
          type: 'number',
          flex: 1,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
        {
          field: 'totalBalance',
          headerName: 'Total Balance',
          type: 'number',
          flex: 1,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
      ] as GridColDef<IAccountBalance>[],
    [],
  );

  useEffect(() => {
    if (project.links[Actions.getAdjustments]) {
      getAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        [
          AdjustmentOperation.Initial,
          AdjustmentOperation.Manual,
          AdjustmentOperation.Add,
          AdjustmentOperation.Subtract,
          AdjustmentOperation.Match,
          AdjustmentOperation.Balance,
          AdjustmentOperation.Fees,
        ],
        undefined,
        type,
      );
    }
  }, [project.links[Actions.getAdjustments]?.href]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter((x) => x.accountType.type === type && x.summaryCode !== 1 && !x.isBalancing),
    [accounts],
  );

  const accountBalances = useMemo<IAccountBalance[]>(() => {
    let accountRecords = filteredAccounts.map((x) => {
      const sourceBalance = initialAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const manualAdjustment = manualAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const glAdjustment = [
        ...glAddAdjustments,
        ...glSubtractAdjustments,
        ...glMatchAdjustments,
        ...glBalanceAdjustments,
      ]
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const fees = feesAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      return {
        accountId: x.id,
        accountName: x.accountName,
        sourceBalance,
        manualAdjustment,
        glAdjustment,
        fees,
        totalBalance: sourceBalance + manualAdjustment + glAdjustment + fees,
      };
    });

    if (!emptyAccountsVisibility) {
      accountRecords = accountRecords.filter((x) => x.totalBalance !== 0);
    }

    if (!unadjustedAccountsVisibility) {
      accountRecords = accountRecords.filter(
        (x) => x.manualAdjustment !== 0 || x.glAdjustment !== 0 || x.fees !== 0,
      );
    }
    return accountRecords;
  }, [
    filteredAccounts,
    initialAdjustments,
    manualAdjustments,
    glAddAdjustments,
    glSubtractAdjustments,
    glMatchAdjustments,
    glBalanceAdjustments,
    feesAdjustments,
    emptyAccountsVisibility,
    unadjustedAccountsVisibility,
  ]);

  const adjustments = useMemo<IAdjustment[]>(
    () => [
      ...initialAdjustments,
      ...manualAdjustments,
      ...glAddAdjustments,
      ...glSubtractAdjustments,
      ...glMatchAdjustments,
      ...glBalanceAdjustments,
      ...feesAdjustments,
    ],
    [
      initialAdjustments,
      manualAdjustments,
      glAddAdjustments,
      glSubtractAdjustments,
      glMatchAdjustments,
      glBalanceAdjustments,
      feesAdjustments,
    ],
  );

  const showLoader = useLoader(getAdjustmentsLoading);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          style={{ height: accountBalances.length ? height : 'auto' }}
          rows={accountBalances}
          density='compact'
          columns={columns}
          className={classes.table}
          initialState={{
            sorting: {
              sortModel: [{ field: 'accountName', sort: 'asc' }],
            },
            aggregation: {
              model: {
                sourceBalance: 'sum',
                manualAdjustment: 'sum',
                glAdjustment: 'sum',
                fees: 'sum',
                totalBalance: 'sum',
              },
            },
          }}
          slots={{
            footer: () => (
              <AccountsTableFooter
                emptyAccountsVisibility={emptyAccountsVisibility}
                unadjustedAccountsVisibility={unadjustedAccountsVisibility}
                onChangeEmptyAccountsVisibility={onChangeEmptyAccountsVisibility}
                onChangeUnadjustedAccountsVisibility={onChangeUnadjustedAccountsVisibility}
                onMinusClicked={onDecreaseTableHeight}
                onPlusClicked={onIncreaseTableHeight}
              />
            ),
          }}
          getRowId={(row) => row.accountId}
        />
        <Collapse in={showDetails}>
          <AdjustmentsTable
            adjustments={adjustments}
            accounts={filteredAccounts}
            category={category}
          />
        </Collapse>
        <Box className={classes.expandButton} onClick={onToggleShowDetails}>
          {!showDetails ? (
            <img src={ChevronDownIcon} alt='chevron-down' />
          ) : (
            <img src={ChevronUpIcon} alt='chevron-up' />
          )}
        </Box>
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
