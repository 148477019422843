import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    padding: 12,
    fontSize: t.typography.body2.fontSize,
    fontWeight: t.typography.body2.fontWeight,
    letterSpacing: t.typography.body2.letterSpacing,
  },
  grid: {
    backgroundColor: t.palette.custom.white[100],
    padding: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    corderColor: t.palette.divider,
    display: 'grid',
    gridTemplateColumns: '4fr 2fr 2fr 2fr 2fr',
    gap: 8,
  },
  header: {
    fontWeight: 600,
  },
  col5: {
    gridColumn: 5,
  },
  col3: {
    gridColumn: 3,
  },
  green: {
    color: t.palette.custom.success[100],
  },
  red: {
    color: t.palette.custom.error[100],
  },
  textRight: {
    textAlign: 'right',
  },
  pl16: {
    paddingLeft: 16,
  },
  col5Spacer: {
    height: 8,
    gridColumnStart: 1,
    gridColumnEnd: 6,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

export default useStyles;
