import { Box, Container, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import clsx from 'clsx';
import useStyles from './styles';

export enum AdjustmentOperation {
  Initial = 'initial',
  Manual = 'manual',
  Add = 'add',
  Subtract = 'subtract',
  Match = 'match',
  Fees = 'fees',
  Balance = 'balance',
}

export const OperationMap: Record<string, string> = {
  initial: 'Initial',
  manual: 'Manual',
  add: 'Add',
  subtract: 'Subtract',
  match: 'Match',
  fees: 'Fees',
  balance: 'Balance',
};

export interface IAdjustmentDetails {
  adjustmentDefinitionId: string;
  sourceSubAccountId?: string | null;
  sourceSubAccountDescription?: string | null;
  operation: string;
  sourceTotal: number;
  matchingBalance: number;
  sourceAdjustment: number;
  adjustments: IAdjustment[];
}

interface IProps {
  details: IAdjustmentDetails;
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
}

export const AdjustmentDetails = ({ details, accounts, adjustments, category }: IProps) => {
  const { classes } = useStyles();

  console.log({ details, accounts, adjustments });

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const getPercentageLabel = useCallback((value: number) => {
    const formatted = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    return formatted;
  }, []);

  const getAccountTotalBalance = useCallback(
    (accountId: string, operations: string[], adjustments: IAdjustment[]) => {
      return adjustments
        .filter((x) => x.accountId === accountId && operations.includes(x.operation))
        .reduce((p, c) => p + c.amount, 0);
    },
    [],
  );

  const detailAdjustments = useMemo(() => {
    let balanceOperations: string[] = [];
    if (
      details.operation === AdjustmentOperation.Add ||
      details.operation === AdjustmentOperation.Add
    ) {
      balanceOperations = [AdjustmentOperation.Initial, AdjustmentOperation.Manual];
    } else if (details.operation === AdjustmentOperation.Fees) {
      balanceOperations = [
        AdjustmentOperation.Initial,
        AdjustmentOperation.Manual,
        AdjustmentOperation.Add,
        AdjustmentOperation.Subtract,
        AdjustmentOperation.Match,
      ];
    }

    const data = details.adjustments.map((x) => ({
      account: accounts.find((a) => a.id === x.accountId),
      balance: getAccountTotalBalance(x.accountId, balanceOperations, adjustments),
      adjustment: x.amount,
    }));
    return data;
  }, [details.adjustments, accounts, adjustments, details.operation]);

  const detailAdjustmentsTotalAdjustment = useMemo(
    () => detailAdjustments.reduce((p, c) => p + c.adjustment, 0),
    [detailAdjustments],
  );

  const detailAdjustmentsTotalAccountsBalance = useMemo(
    () => detailAdjustments.reduce((p, c) => p + c.balance, 0),
    [detailAdjustments],
  );

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          {details.operation === AdjustmentOperation.Add ||
          details.operation === AdjustmentOperation.Subtract ||
          details.operation === AdjustmentOperation.Fees ? (
            <Box className={classes.grid}>
              <Box className={classes.header}>{OperationMap[details.operation]}</Box>
              <Box className={classes.col5Spacer} />
              <Box className={classes.header}>Line Item Balance</Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {getBalanceLabel(details.sourceTotal)}
              </Box>
              <Box className={classes.col5Spacer} />
              <Box className={classes.header}>Apply Adjustment To</Box>
              <Box className={clsx([classes.header, classes.col3, classes.textRight])}>
                Adjusted Balance
              </Box>
              <Box className={clsx([classes.header, classes.textRight])}>Portion of Total</Box>
              <Box className={clsx([classes.header, classes.textRight])}>Adjustment</Box>

              {detailAdjustments.map((x) => (
                <>
                  <Box className={clsx([classes.pl16, !x.account && classes.red])}>
                    {x.account?.accountName || (
                      <Tooltip
                        title={`This account is not of the correct type (in this case, ${category}). Adjustments defined within a particular account category should only target accounts of the corresponding category.`}
                      >
                        <Box className={classes.flex}>
                          <img src={AlertIcon} alt='alert' />
                          Some non category account
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  <Box
                    className={clsx([classes.col3, classes.textRight, !x.account && classes.red])}
                  >
                    {getBalanceLabel(x.balance)}
                  </Box>
                  <Box className={clsx([classes.textRight, !x.account && classes.red])}>
                    {getPercentageLabel(
                      detailAdjustmentsTotalAccountsBalance
                        ? x.balance / detailAdjustmentsTotalAccountsBalance
                        : 1 / detailAdjustments.length,
                    )}
                  </Box>
                  <Box className={clsx([classes.green, classes.textRight])}>
                    {getBalanceLabel(x.adjustment)}
                  </Box>
                </>
              ))}

              <Box className={clsx([classes.pl16, classes.header])}>Total</Box>
              <Box className={clsx([classes.header, classes.col3, classes.textRight])}>
                {getBalanceLabel(detailAdjustmentsTotalAccountsBalance)}
              </Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {getPercentageLabel(1)}
              </Box>
              <Box className={classes.col5Spacer} />
              <Box className={classes.header}>Total Adjustment</Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {getBalanceLabel(details.sourceAdjustment)}
              </Box>
              <Box
                className={clsx([classes.header, classes.green, classes.col5, classes.textRight])}
              >
                {getBalanceLabel(detailAdjustmentsTotalAdjustment)}
              </Box>
            </Box>
          ) : (
            <Typography variant='h6'>Not implemented yet</Typography>
          )}
        </Container>
      </Box>
    </>
  );
};
