import {
  AdjustmentDetails,
  AdjustmentOperation,
  IAdjustmentDetails,
  OperationMap,
} from '../AdjustmentDetails';
import { DataGridPremium, DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { StandardTableFooter } from '../StandardTableFooter';
import useStyles from './styles';

interface IProps {
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
}

export const AdjustmentsTable = ({ adjustments, accounts, category }: IProps) => {
  const { classes } = useStyles();

  const [height, setHeight] = useState(250);

  const getBalanceLabel = useCallback((value: number) => {
    const formatted = `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(value))}`;
    return value < 0 ? `(${formatted})` : formatted;
  }, []);

  const onDecreaseTableHeight = () => {
    setHeight((prev) => Math.max(prev/2, 250));
  };

  const onIncreaseTableHeight = () => {
    setHeight((prev) => Math.min(prev*2, 1000));
  };

  const columns = useMemo(
    () =>
      [
        {
          field: 'sourceSubAccountId',
          headerName: 'Balance Sheet Line Item',
          type: 'string',
          flex: 2,
        },
        {
          field: 'sourceSubAccountDescription',
          headerName: 'Description',
          type: 'string',
          flex: 2,
        },
        {
          field: 'operation',
          headerName: 'Operation',
          type: 'string',
          flex: 1,
          renderCell: (params) => OperationMap[params.value] || params.value,
        },
        {
          field: 'sourceTotal',
          headerName: 'Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
        {
          field: 'matchingBalance',
          headerName: 'Matching Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) =>
            params.value === Number.MIN_VALUE ? 'N/A' : getBalanceLabel(params.value || 0),
        },
        {
          field: 'sourceAdjustment',
          headerName: 'Adjustment',
          type: 'number',
          flex: 2,
          renderCell: (params) => getBalanceLabel(params.value || 0),
        },
      ] as GridColDef<IAdjustmentDetails>[],
    [],
  );

  const adjustmentDetails = useMemo<IAdjustmentDetails[]>(() => {
    const grouped = new Map<string, IAdjustmentDetails>();

    adjustments
      .filter((x) =>
        [
          AdjustmentOperation.Add,
          AdjustmentOperation.Subtract,
          AdjustmentOperation.Match,
          AdjustmentOperation.Balance,
          AdjustmentOperation.Fees,
        ].includes(x.operation as AdjustmentOperation),
      )
      .forEach((adjustment) => {
        const {
          adjustmentDefinitionId,
          sourceSubAccountId,
          sourceSubAccountDescription,
          operation,
          sourceAdjustment,
          sourceTotal,
        } = adjustment;

        if (!grouped.has(adjustmentDefinitionId)) {
          grouped.set(adjustmentDefinitionId, {
            adjustmentDefinitionId,
            sourceSubAccountId,
            sourceSubAccountDescription,
            operation,
            sourceAdjustment,
            sourceTotal,
            matchingBalance:
              operation === AdjustmentOperation.Match || operation === AdjustmentOperation.Balance
                ? sourceTotal - sourceAdjustment
                : Number.MIN_VALUE,
            adjustments: [],
          });
        }

        const group = grouped.get(adjustmentDefinitionId);

        if (group) {
          group.adjustments.push(adjustment);
        }
      });

    return Array.from(grouped.values());
  }, [adjustments]);

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(
    ({ row }) => (
      <AdjustmentDetails
        details={row}
        accounts={accounts}
        adjustments={adjustments}
        category={category}
      />
    ),
    [accounts, adjustments],
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          style={{ height: adjustmentDetails.length ? height : 'auto' }}
          rows={adjustmentDetails}
          density='compact'
          columns={columns}
          className={classes.table}
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceSubAccountId', sort: 'asc' }],
            },
          }}
          slots={{
            footer: () => (
              <StandardTableFooter
                onMinusClicked={onDecreaseTableHeight}
                onPlusClicked={onIncreaseTableHeight}
              />
            ),
          }}
          getRowId={(row) => row.adjustmentDefinitionId}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
      </Box>
    </>
  );
};
